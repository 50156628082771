import { render, staticRenderFns } from "./day-plan-slot-lesson-item.vue?vue&type=template&id=f06de988&scoped=true"
import script from "./day-plan-slot-lesson-item.vue?vue&type=script&lang=js"
export * from "./day-plan-slot-lesson-item.vue?vue&type=script&lang=js"
import style0 from "./day-plan-slot-lesson-item.vue?vue&type=style&index=0&id=f06de988&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f06de988",
  null
  
)

export default component.exports