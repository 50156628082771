<template>
  <div
    class="day-plan-slot-lesson-item"
    :class="{'is-canceled': actualLesson.isCanceled}"
    :style="{backgroundColor}"
    :title="tooltipText"
  >
    <span class="text-truncate">{{ title }}</span>
  </div>
</template>


<script>
export default {
  name: 'DayPlanSlotLessonItem',
  props: {
    actualLesson: Object,
    mini: Boolean
  },
  computed: {
    course() {
      return this.$store.getters['common/courseByIri'](this.actualLesson?.scheduledLesson?.course)
    },
    schoolSubject() {
      return this.actualLesson?.getSchoolSubject()
    },
    backgroundColor() {
      return this.schoolSubject?.color
    },
    tooltipText() {
      return this.mini && (this.schoolSubject?.name || 'KEIN FACH')
    },
    title() {
      const schoolClass = this.actualLesson?.scheduledLesson?.schoolClass
      const { course, schoolSubject } = this
      const name = schoolSubject?.name || 'KEIN FACH'
      if (schoolClass && !course) return name
      if (course && !schoolClass) return `${course.code}: ${name}`
      return '???'
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.day-plan-slot-lesson-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 10px;
  height: 100%;
  min-height: 16px;
  border-radius: 4px;
  background: $default-school-subject-color;

  &.is-canceled {
    opacity: 0.5;

    & > * {
      text-decoration: line-through;
    }
  }

  & > * {
    position: absolute;
    width: 100%;
  }
}
</style>
