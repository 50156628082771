<template>
  <DetailsDrawerSection title="Anwesenheiten">

    <template v-slot:quick-actions>
      <v-btn
        icon
        small
        :disabled="loading"
        @click="showEditDialog = true"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <LessonAbsencesStats
      v-if="studentsCount > 0"
      :present="isAbsentFalse"
      :absent="isAbsentTrue"
      :unknown="isAbsentNull"
      class="flex-grow-1 text-center"
    />

    <EditLessonAbsencesDialog
      :show="showEditDialog"
      :actual-lesson="actualLesson"
      @save-done="dirty = true"
      @close="closeEditDialog"
    />

  </DetailsDrawerSection>
</template>


<script>
import apiClient from '@/api-client'
import DetailsDrawerSection from '@/pages/schedule.page/details-drawer/details-drawer-section'
import LessonAbsencesStats from '@/components/lesson-absences-stats'
import EditLessonAbsencesDialog from '@/components/edit-lesson-absences-dialog'

export default {
  name: 'AbsencesSection',
  components: {
    DetailsDrawerSection,
    LessonAbsencesStats,
    EditLessonAbsencesDialog
  },
  props: {
    actualLesson: Object,
    loading: Boolean
  },
  data: () => ({
    studentsCount: 0,
    isAbsentTrue: 0,
    isAbsentFalse: 0,
    showEditDialog: false,
    dirty: false
  }),
  computed: {
    isAbsentNull() {
      return Math.max(0, this.studentsCount - this.isAbsentFalse - this.isAbsentTrue)
    }
  },
  watch: {
    actualLesson() {
      this.loadStudentsCount()
      this.loadIsAbsentCount(true)
      this.loadIsAbsentCount(false)
    }
  },
  created() {
    this.loadStudentsCount()
    this.loadIsAbsentCount(true)
    this.loadIsAbsentCount(false)
  },
  methods: {
    async loadStudentsCount() {
      const { schoolClass, course } = this.actualLesson?.scheduledLesson || {}

      if (schoolClass && course) {
        console.warn('ScheduledLesson schould not be related to the SchoolClass and to the Course simultaneously')
        return
      }
      if (!(schoolClass || course)) {
        console.warn('ScheduledLesson schould be related to the SchoolClass or to the Course')
        return
      }

      const criteria = {
        schoolClass: schoolClass || undefined,
        course: course || undefined,
      }
      try {
        this.studentsCount = await apiClient[schoolClass ? 'schoolClassStudents' : 'courseStudents'].countBy(criteria)
      } catch (e) {
        console.error(e)
        this.studentsCount = 0
      }
    },
    async loadIsAbsentCount(isAbsent) {
      const actualLesson = this.actualLesson?.['@id']
      if (!actualLesson) return
      const paramName = isAbsent ? 'isAbsentTrue' : 'isAbsentFalse'
      const criteria = {actualLesson, isAbsent}
      try {
        this[paramName] = await apiClient.studentAbsences.countBy(criteria)
      } catch (e) {
        console.error(e)
        this[paramName] = 0
      }
    },
    closeEditDialog() {
      this.showEditDialog = false
      if (this.dirty) {
        this.$emit('refresh')
        this.dirty = false
      }
    }
  }
}
</script>
