<template>
  <v-navigation-drawer
    app
    right
    clipped
    touchless
    disable-route-watcher
    disable-resize-watcher
    class="d-print-none"
    :width="mini ? '100%' : '360'"
    :value="!!value"
    @input="!$event && $emit('close')"
  >
    <template v-slot:prepend>
      <Header
        v-if="value"
        :day="value.day"
        :day-plan-slot="value.dayPlanSlot"
        :loading="loading"
        @close="$emit('close')"
      />
      <v-divider />
    </template>

    <div
      v-if="value"
      class="px-1 py-2"
    >
      <DayPlanSlotLesson
        v-for="actualLesson in actualLessons"
        :key="actualLesson['@id']"
        :actual-lessons="[actualLesson]"
        :disabled="actualLessons.length === 1 || loading"
        :selected="actualLessons.length > 1 && actualLesson.id === activeActualLessonId"
        :class="{muted: actualLessons.length > 1 && actualLesson.id !== activeActualLessonId}"
        class="px-2 py-1"
        @click="activeActualLessonId = actualLesson.id"
      />

      <template v-if="actualLessonData">
        <ManageSection
          v-if="showManageSection"
          :loading="loading"
          :actual-lesson="actualLessonData"
          @change:is-canceled="saveIsCanceled"
          @show-edit-replacement-dialog="showEditReplacementDialog = true"
        />

        <ReplacementSection
          v-if="showReplacementSection"
          :loading="loading"
          :actual-lesson="actualLessonData"
          :can-edit="canWriteActualLessonReplacement"
          @edit-replacement="showEditReplacementDialog = true"
          @delete-replacement="deleteReplacement"
        />

        <EditActualLessonReplacementDialog
          v-if="showEditReplacementDialog && canWriteActualLessonReplacement && !actualLessonData.isCanceled"
          :day-plan-slot="value.dayPlanSlot"
          :actual-lesson="actualLessonData"
          :mini="mini"
          @save="saveReplacement"
          @close="showEditReplacementDialog = null"
        />

        <AbsencesSection
          v-if="showAbsencesSection"
          :actual-lesson="actualLessonData"
          :loading="loading"
          @refresh="loadActualLessonData()"
        />

        <TextsSection
          v-if="showTextsSection"
          :actual-lesson="actualLessonData"
          :loading="loading"
          @change:texts="saveTexts"
        />

        <WeekReportSection
          v-if="showWeekReportSection"
          :actual-lesson="actualLessonData"
          :loading="loading"
        />

        <WeekCommentsSection
          :actual-lesson="actualLessonData"
          :loading="loading"
          @show:edit-week-comments-dialog="$emit('show:edit-week-comments-dialog', $event)"
        />
      </template>

      <v-skeleton-loader
        v-else-if="loading"
        type="list-item@7"
      ></v-skeleton-loader>

    </div>
  </v-navigation-drawer>
</template>


<script>
import apiClient from '@/api-client'
import AbsencesSection from '@/pages/schedule.page/details-drawer/absences-section'
import DayPlanSlotLesson from '@/components/schedule-day/day-plan-slot-lesson'
import Header from '@/pages/schedule.page/details-drawer/header'
import ManageSection from '@/pages/schedule.page/details-drawer/manage-section'
import EditActualLessonReplacementDialog from '@/pages/schedule.page/details-drawer/edit-actual-lesson-replacement-dialog'
import ReplacementSection from '@/pages/schedule.page/details-drawer/replacement-section'
import TextsSection from '@/pages/schedule.page/details-drawer/texts-section'
import WeekReportSection from '@/pages/schedule.page/details-drawer/week-report-section'
import WeekCommentsSection from '@/pages/schedule.page/details-drawer/week-comments-section'

export default {
  name: 'DetailsDrawer',
  components: {
    AbsencesSection,
    DayPlanSlotLesson,
    Header,
    ManageSection,
    EditActualLessonReplacementDialog,
    ReplacementSection,
    TextsSection,
    WeekReportSection,
    WeekCommentsSection
  },
  props: {
    value: Object,
    mini: Boolean
  },
  data: () => ({
    loading: false,
    activeActualLessonId: null,
    actualLessonData: null,
    showEditReplacementDialog: false
  }),
  computed: {
    school() {
      return this.$store.state.school || {}
    },
    actualLessons() {
      return this.value?.dayPlanSlot?.actualLessons || []
    },
    canWriteActualLessonReplacement() {
      return this.$store.getters['canWriteActualLessonReplacement']
    },
    showManageSection() {
      const l = this.actualLessonData
      return !!l && !l.replacement && this.canWriteActualLessonReplacement
    },
    showReplacementSection() {
      return !!this.actualLessonData?.replacement
    },
    showAbsencesSection() {
      return !this.value?.day?.isFuture && !!this.actualLessonData
    },
    showTextsSection() {
      return !this.school.useWeekReports
    },
    showWeekReportSection() {
      return !!this.school.useWeekReports
    }
  },
  watch: {
    actualLessons() {
      this.activeActualLessonId = this.actualLessons[0]?.id || null
    },
    activeActualLessonId(id) {
      if (id) this.loadActualLessonData()
      else this.actualLessonData = null
    },
    loading() {
      this.$emit('change:loading', this.loading)
    }
  },
  methods: {
    async loadActualLessonData() {
      const id = this.activeActualLessonId
      if (!id) return
      this.loading = true
      try {
        this.actualLessonData = await apiClient.actualLessons.find(id, {full: true, includeScheduledLesson: true})
      } catch (e) {
        this.actualLessonData = null
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async saveIsCanceled({ patch, done, error }) {
      this.loading = true
      try {
        await apiClient.actualLessons.updateIsCanceled(this.activeActualLessonId, patch.isCanceled)
        await this.loadActualLessonData()
        this.$emit('updated:actual-lesson', this.actualLessonData)
        done()
      } catch (e) {
        console.error(e)
        error()
      } finally {
        this.loading = false
      }
    },
    async saveReplacement({ patch, done, error }) {
      this.loading = true
      try {
        await apiClient.actualLessons.updateReplacement(this.activeActualLessonId, patch)
        await this.loadActualLessonData()
        this.$emit('updated:actual-lesson', this.actualLessonData)
        done()
      } catch (e) {
        console.error(e)
        error(apiClient.getErrorReason(e))
      } finally {
        this.loading = false
      }
    },
    async deleteReplacement({ done, error }) {
      if (!this.actualLessonData?.replacement) return done()
      this.loading = true
      try {
        await apiClient.actualLessons.removeReplacement(this.activeActualLessonId)
        await this.loadActualLessonData()
        this.$emit('updated:actual-lesson', {...this.actualLessonData, replacement: null})
        done()
      } catch (e) {
        console.error(e)
        error()
      } finally {
        this.loading = false
      }
    },
    async saveTexts({ patch, done, error }) {
      this.loading = true
      try {
        await apiClient.actualLessons.update(this.activeActualLessonId, patch)
        await this.loadActualLessonData()
        done()
      } catch (e) {
        console.error(e)
        error(apiClient.getErrorReason(e))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.v-navigation-drawer {
  height: 100% !important;
}

.day-plan-slot-lesson.muted {
  opacity: 0.7;
  transform: scale(0.95);
}
</style>
