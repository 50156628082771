<template>
  <v-dialog
    persistent
    scrollable
    max-width="1024"
    :value="show"
    :fullscreen="fullscreen"
  >
    <v-card
      v-if="show"
      :height="fullscreen ? null : '90vh'"
    >

      <v-card-title class="subtitle-1 justify-center">

        <v-icon class="mr-4">mdi-account-check-outline</v-icon>

        <div class="text-sm-h6">Anwesenheiten</div>

        <small class="flex-grow-1 text-center text-uppercase">
          {{ actualLesson.date | dateForHumans }}
        </small>

        <DayPlanSlotLesson
          :day-plan-slot="dayPlanSlot"
          :actual-lessons="[actualLesson]"
          disabled
        />

      </v-card-title>

      <v-divider/>

      <v-card-text
        class="pa-1 overflow-hidden"
        ref="tableWrap"
      >
        <v-skeleton-loader
          v-if="loading"
          type="table-tbody@2"
        />

        <div
          v-else-if="tableRows.length === 0"
          class="fill-height d-flex flex-column justify-center"
        >
          <v-alert
            prominent
            text
            type="warning"
            class="mx-auto"
          >
            Keine zugeordneten Schüler
          </v-alert>
        </div>

        <v-simple-table
          v-else
          dense
          fixed-header
          class="my-1"
          :height="tableHeight"
        >
          <thead>
            <tr>
              <th class="col-check">
                <v-simple-checkbox
                  v-if="canEdit"
                  :ripple="false"
                  :indeterminate="!allEditableStudentsChecked && someStudentsChecked"
                  :disabled="loading"
                  v-model="allEditableStudentsChecked"
                />
              </th>
              <th class="col-avatar"></th>
              <th class="col-name">Name</th>
              <th
                v-if="isCourse"
                class="col-school-class"
              >Klasse</th>
              <th
                v-for="dayPlanSlotItem in dayPlanSlots"
                :key="dayPlanSlotItem['@id']"
                :set="isActive = dayPlanSlotItem === dayPlanSlot"
                class="pa-0"
              >
                <div
                  class="th-day-plan-slot-inner"
                  :class="{active: isActive}"
                >
                  <small>{{ dayPlanSlotItem.startsAt | trimZeroesLeft }}</small>
                  <small>{{ dayPlanSlotItem.endsAt | trimZeroesLeft }}</small>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="{ student, schoolClassCode, isEditable, columns } of tableRows"
              :key="student['@id']"
            >
              <td class="col-check">
                <v-simple-checkbox
                  v-if="isEditable"
                  :ripple="false"
                  :value="checkedStudents.has(student)"
                  :disabled="loading"
                  @input="toggleCheckedStudent(student)"
                />
              </td>
              <td class="col-avatar">
                <v-avatar size="40">
                  <v-img
                    v-if="student.avatar"
                    :src="student.avatar"
                  />
                  <v-icon
                    v-else
                    v-text="student.icon"
                  />
                </v-avatar>
              </td>
              <td class="col-name">
                <div v-text="student.displayName" />
              </td>
              <td
                v-if="isCourse"
                class="col-school-class"
              >
                {{ schoolClassCode }}
              </td>
              <td
                v-for="column in columns"
                :key="column.dayPlanSlot['@id']"
                class="td-day-plan-slot pa-0"
              >
                <div
                  class="td-day-plan-slot-inner"
                  :class="{active: column.isActive}"
                >
                  <AbsenceBtn
                    v-if="isEditable && column.isActive && column.hasActualLessons"
                    :student-absence="column.studentAbsence"
                    :disabled="loading || someStudentsChecked"
                    @click="openEditAbsenceDialog(student)"
                  />
                  <AbsenceChip
                    v-else-if="column.studentAbsence"
                    :student-absence="column.studentAbsence"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div
          v-if="checkedStudents.size > 0"
          class="checked-students-toolbar"
        >
          <span class="align-self-end">
            {{ allStudentsChecked ? 'Alle' : checkedStudents.size }}
            Schüler:
          </span>

          <v-btn-toggle
            rounded
            class="elevation-2"
          >
            <v-btn
              small
              color="success"
              title="Als anwesend markieren"
              :disabled="loading || saving"
              @click="setCheckedStudentsIsAbsent(false)"
            >
              <v-icon small>
                mdi-account-multiple-check
              </v-icon>
            </v-btn>
            <v-btn
              small
              color="error"
              title="Als abwesend markieren"
              :disabled="loading || saving"
              @click="setCheckedStudentsIsAbsent(true)"
            >
              <v-icon small>
                mdi-account-multiple-minus-outline
              </v-icon>
            </v-btn>
            <v-btn
              small
              title="Anwesenheit eintragen"
              :disabled="loading || saving"
              @click="openEditAbsenceDialog()"
            >
              <v-icon small>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-card-text>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="$emit('close')"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>

    <EditLessonAbsenceDialog
      :data="editAbsenceDialogData"
      :loading="saving"
      @student-absence:set-student="openEditAbsenceDialog"
      @student-absence:save="saveStudentAbsence"
      @close="closeEditAbsenceDialog()"
    />
  </v-dialog>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import apiClient from '@/api-client'
import DayPlanSlotLesson from '@/components/schedule-day/day-plan-slot-lesson'
import AbsenceBtn from '@/components/edit-lesson-absences-dialog/absence-btn'
import AbsenceChip from '@/components/edit-lesson-absences-dialog/absence-chip'
import EditLessonAbsenceDialog from '@/components/edit-lesson-absences-dialog/edit-lesson-absence-dialog'
import { dateForHumans } from '@/helpers/datetime'
import { trimZeroesLeft } from '@/helpers/string'

export default {
  name: 'EditLessonAbsencesDialog',
  components: {
    DayPlanSlotLesson,
    AbsenceBtn,
    AbsenceChip,
    EditLessonAbsenceDialog
  },
  filters: {
    dateForHumans,
    trimZeroesLeft
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    actualLesson: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tableHeight: null,
    loading: false,
    saving: false,
    students: null,
    actualLessons: null,
    studentAbsences: null,
    checkedStudents: new Set(),
    editAbsenceDialogData: null
  }),
  computed: {
    ...mapGetters(['canWriteStudentAbsenceByActualLesson']),
    ...mapGetters('common', [
      'dayPlanByIri',
      'dayPlanSlotByIri',
      'studentSchoolClassByAcademicYear'
    ]),
    ...mapState('schoolCalendar', ['academicYear']),
    fullscreen() {
      return this.$vuetify.breakpoint.mobile
    },
    dayPlanSlot() {
      return this.dayPlanSlotByIri(this.actualLesson?.scheduledLesson?.dayPlanSlot)
    },
    dayPlan() {
      return this.dayPlanByIri(this.dayPlanSlot?.dayPlan)
    },
    dayPlanSlots() {
      if (!this.actualLessons) return []
      const { dayOfWeek } = this.dayPlanSlot
      const dayPlanSlots = this.dayPlan.dayPlanSlots
        .filter(s => s.dayOfWeek === dayOfWeek)
        .filter(({ type }) => 'lesson' === type)
        .filter(s => this.actualLessons.find(l => s['@id'] === l.scheduledLesson?.dayPlanSlot))
      const { breakpoint } = this.$vuetify
      if (breakpoint.mdAndUp) {
        return dayPlanSlots
      }
      const index = dayPlanSlots.findIndex(item => item === this.dayPlanSlot)
      if (breakpoint.smAndUp) {
        return dayPlanSlots.slice(Math.max(0, index - 2), index + 1)
      }
      return dayPlanSlots.slice(index, index + 1)
    },
    tableRows() {
      if (!(this.students && this.actualLessons && this.studentAbsences)) return []
      return this.students.map(student => {
        const schoolClassCode = this.studentSchoolClassByAcademicYear(student, this.academicYear)?.code
        const isEditable = this.canWriteStudentAbsenceByActualLesson(this.actualLesson, student)
        const columns = this.dayPlanSlots.map(dayPlanSlot => {
          const isActive = dayPlanSlot === this.dayPlanSlot
          const actualLessons = this.actualLessons.filter(l => dayPlanSlot['@id'] === l.scheduledLesson?.dayPlanSlot)
          const hasActualLessons = actualLessons.length > 0
          const studentAbsence = hasActualLessons && this.studentAbsences.find(a =>
            a.student === student['@id'] && actualLessons.some(l => l['@id'] === a.actualLesson)
          )
          return {dayPlanSlot, isActive, hasActualLessons, studentAbsence}
        })
        return {student, schoolClassCode, isEditable, columns}
      })
    },
    editableTableRows() {
      return this.tableRows.filter(row => row.isEditable)
    },
    canEdit() {
      return this.editableTableRows.length > 0
    },
    isCourse() {
      const { course, schoolClass } = this.actualLesson?.scheduledLesson || {}
      return !!course && !schoolClass
    },
    allStudentsChecked() {
      const { length } = this.students
      return length > 0 && length === this.checkedStudents.size
    },
    allEditableStudentsChecked: {
      get() {
        const { length } = this.editableTableRows
        return length > 0 && length === this.checkedStudents.size
      },
      set(isTrue) {
        this.checkedStudents = new Set(isTrue ? this.editableTableRows.map(r => r.student) : [])
      }
    },
    someStudentsChecked() {
      return this.checkedStudents.size > 0
    }
  },
  watch: {
    async show(isShown) {
      this.editAbsenceDialogData = null
      this.checkedStudents = new Set()
      this.studentAbsences = null
      this.actualLessons = null
      this.students = null
      if (isShown) {
        this.loading = true
        await this.$store.dispatch('common/fetchCollectionOnce', 'absenceReasons')
        await this.loadStudents()
        await Promise.all([
          this.loadActualLessons(),
          this.loadStudentAbsences()
        ])
        this.loading = false
        this.$nextTick(this.resetTableHeight)
      }
      this.resetHeightWatcher(isShown)
    }
  },
  methods: {
    resetHeightWatcher(isShown) {
      const fn = isShown ? 'addEventListener' : 'removeEventListener'
      window[fn]('resize', this.resetTableHeight)
      window[fn]('orientationchange', this.resetTableHeight)
    },
    resetTableHeight() {
      const { tableWrap } = this.$refs
      if (tableWrap) {
        this.tableHeight = tableWrap.clientHeight - 16
      }
    },
    async loadStudents() {
      this.students = await apiClient.students.findByActualLesson(this.actualLesson)
    },
    async loadActualLessons() {
      const schoolClasses = [...new Set(
        this.students
          .map(student => this.studentSchoolClassByAcademicYear(student, this.academicYear))
          .map(schoolClass => schoolClass?.['@id'])
          .filter(iri => !!iri)
      )]

      const { date } = this.actualLesson
      const criteria = {startDate: date, endDate: date}
      const options = {brief: true, includeScheduledLesson: true}
      const getLessons = (schoolClass) => apiClient.actualLessons.findBySchoolClass(schoolClass, criteria, options)
      this.actualLessons = [].concat(...await Promise.all(schoolClasses.map(getLessons)))
    },
    async loadStudentAbsences() {
      this.studentAbsences = await apiClient.studentAbsences.findBy({
          student: this.students.map(s => s['@id']),
          'actualLesson.date': this.actualLesson.date,
      })
    },
    toggleCheckedStudent(student) {
      const checkedStudents = new Set([...this.checkedStudents])
      if (checkedStudents.has(student)) {
        checkedStudents.delete(student)
      } else {
        checkedStudents.add(student)
      }
      this.checkedStudents = checkedStudents
    },
    setCheckedStudentsIsAbsent(isAbsent) {
      this.saveStudentAbsence({
        students: [...this.checkedStudents],
        studentAbsence: {
          isAbsent,
          cameLater: 0,
          leftEarlier: 0,
          absenceReason: null
        }
      })
    },
    async saveStudentAbsence({ students, studentAbsence, done, error }) {
      this.saving = true
      const { date } = this.actualLesson
      const { hourNumber } = this.dayPlanSlot
      const { isAbsent, cameLater, leftEarlier, absenceReason, comment } = studentAbsence
      const patch = {
        startDate: date,
        startHourNumber: hourNumber,
        endDate: date,
        endHourNumber: hourNumber,
        isAbsent,
        cameLater,
        leftEarlier,
        absenceReason,
        comment,
      }
      try {
        await apiClient.studentAbsences.updateByStudents(students, patch)
        this.loadStudentAbsences()
        if (this.someStudentsChecked) {
          this.allEditableStudentsChecked = false
        }
        if (done) done()
      } catch (e) {
        console.error(e)
        if (error) error(apiClient.getErrorReason(e))
      } finally {
        this.saving = false
        this.$emit('save-done')
      }
    },
    openEditAbsenceDialog(student) {
      this.editAbsenceDialogData = {
        dayPlanSlot: this.dayPlanSlot,
        actualLesson: this.actualLesson,
        studentAbsences: this.studentAbsences,
        students: student ? [student] : [...this.checkedStudents],
        allStudents: this.editableTableRows.map(r => r.student)
      }
    },
    closeEditAbsenceDialog() {
      this.editAbsenceDialogData = null
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.day-plan-slot-lesson {
  max-width: 380px;
}

.th-day-plan-slot-inner,
.td-day-plan-slot-inner {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.active {
    background: $color-selection-bg;
  }
}

.th-day-plan-slot-inner {
  line-height: 1.1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.td-day-plan-slot {
  height: 41px !important;
}

tbody > tr:last-child .td-day-plan-slot-inner.active {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.col-check {
  width: 2rem;
  padding-right: 0 !important;
}

.col-check,
.col-avatar {
  text-align: center !important;
}

td.col-avatar {
  height: 45px;
  width: 40px;
  padding: 0 !important;
}

td.col-name {
  overflow: hidden;
  line-height: 1.1;

  & > * {
    max-height: 44px;
  }
}

.col-school-class {
  width: 5rem;
  padding: 0 !important;
  text-align: center !important;
}

@media screen and (max-width: 959px) {
  .col-name {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  td.col-name,
  td.col-school-class {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 960px) {
  .col-name {
    width: 14rem;
  }
}

.v-card__text {
  position: relative;
}

.checked-students-toolbar {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 56px;
  height: 39px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 0;
  background: #fff;
  display: flex;
  align-items: center;

  & > * {
    margin: 0 0.25rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 50px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
}
</style>
