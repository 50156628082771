<template>
  <DetailsDrawerSection title="Wochenbezogene Bemerkungen">

    <template v-slot:quick-actions>
      <v-btn
        icon
        small
        :disabled="loading"
        @click="showEditWeekCommentsDialog()"
      >
        <v-icon>{{ canWriteWeekComment ? 'mdi-pencil' : 'mdi-application' }}</v-icon>
      </v-btn>
    </template>

    <LessonTextField :value="text" />

  </DetailsDrawerSection>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import apiClient from '@/api-client'
import { getWeekOfYear } from '@/helpers/datetime'
import DetailsDrawerSection from '@/pages/schedule.page/details-drawer/details-drawer-section'
import LessonTextField from '@/components/lesson-text-field'

export default {
  name: 'WeekCommentsSection',
  components: {
    DetailsDrawerSection,
    LessonTextField
  },
  props: {
    actualLesson: {
      type: Object,
      required: true
    },
    loading: Boolean
  },
  data: () => ({
    weekComments: []
  }),
  computed: {
    ...mapState(['user']),
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters(['canWriteWeekComment']),
    ...mapGetters('common', ['teacherByIri']),
    text() {
      return this.weekComments.map(c => {
        const teacher = this.teacherByIri(c.teacher)
        return teacher ? `${teacher?.code}: ${c.text}` : c.text
      }).join('\n')
    }
  },
  watch: {
    actualLesson() {
      this.weekComments = []
      this.loadWeekComments()
    }
  },
  created() {
    this.loadWeekComments()
  },
  methods: {
    async loadWeekComments() {
      const { date, scheduledLesson } = this.actualLesson || {}
      const { schoolClass, course } = scheduledLesson
      const contextObject = schoolClass || course
      const calendarWeekCode = date && getWeekOfYear(date).toString()
      if (!(contextObject && calendarWeekCode)) return
      this.weekComments = await apiClient.weekComments.findBy({contextObject, calendarWeekCode})
    },
    showEditWeekCommentsDialog() {
      const { date, scheduledLesson } = this.actualLesson || {}
      const { schoolClass, course } = scheduledLesson || {}
      const contextObject = schoolClass || course
      if (contextObject && date) {
        const { section } = this
        const weekId = getWeekOfYear(date).toString()
        this.$emit('show:edit-week-comments-dialog', {contextObject, section, weekId})
      }
    }
  }
}
</script>
