<template>
  <div
    class="day-plan-slot-lesson-item"
    :class="{'is-canceled': actualLesson.isCanceled}"
    :style="{backgroundColor}"
    :title="tooltipText"
  >
    <div class="lesson-row">
      <span class="font-weight-bold text-truncate">{{ title }}</span>
    </div>
    <div class="lesson-row">
      <span class="text-truncate">{{ subTitle }}</span>
    </div>
    <v-icon
      v-if="showAlert"
      class="lesson-alert"
    >mdi-exclamation</v-icon>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DayPlanSlotLessonItem',
  props: {
    actualLesson: Object,
    mini: Boolean
  },
  computed: {
    ...mapGetters('common', [
      'courseByIri',
      'teacherByIri',
    ]),
    course() {
      return this.courseByIri(this.actualLesson?.scheduledLesson?.course)
    },
    teachers() {
      const { teachers } = this.actualLesson?.replacement || this.actualLesson?.scheduledLesson || {}
      return (teachers || []).map(this.teacherByIri)
    },
    schoolSubject() {
      return this.actualLesson?.getSchoolSubject()
    },
    backgroundColor() {
      return this.schoolSubject?.color
    },
    tooltipText() {
      return this.mini && (this.schoolSubject?.name || 'KEIN FACH')
    },
    title() {
      const schoolClass = this.actualLesson?.scheduledLesson?.schoolClass
      const { course, schoolSubject } = this
      const name = schoolSubject?.name || 'KEIN FACH'
      if (schoolClass && !course) return name
      if (course && !schoolClass) return `${course.code}: ${name}`
      return '???'
    },
    subTitle() {
      const { teachers } = this
      if (teachers.length === 1 && !this.mini) {
        const [ teacher ] = teachers
        return `${teacher.lastName || '?'} (${teacher.code || '?'})`.trim()
      }
      return teachers.map(t => t.code || '?').join(', ')
    },
    showAlert() {
      return !!this.actualLesson?.replacement || !!this.actualLesson?.isCanceled
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.day-plan-slot-lesson-item {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
  background: $default-school-subject-color;

  &.is-canceled {
    opacity: 0.5;

    .lesson-row:first-child > * {
      text-decoration: line-through;
    }
  }

  .lesson-row {
    position: relative;
    min-height: 18px;

    & > * {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .lesson-alert {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    color: red;
  }
}
</style>
