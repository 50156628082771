<template>
  <div
    class="lesson-chip-multiple-item"
    :style="{backgroundColor}"
    :class="{canceled: lesson.isCanceled}"
    v-text="title"
  />
</template>


<script>
export default {
  name: 'LessonChipMultipleItem',
  props: {
    lesson: {
      type: Object,
      required: true
    }
  },
  computed: {
    course() {
      return this.$store.getters['common/courseByIri'](this.lesson.scheduledLesson?.course)
    },
    schoolSubject() {
      return this.lesson.getSchoolSubject()
    },
    backgroundColor() {
      return this.schoolSubject?.color
    },
    title() {
      const { schoolClass, course, schoolSubject } = this
      const name = schoolSubject?.name || 'KEIN FACH'
      if (schoolClass && !course) return name
      if (course && !schoolClass) return `${course.code}: ${name}`
      return '???'
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.lesson-chip-multiple-item {
  border-radius: 10px;
  white-space: nowrap;
  margin: 1px;
  padding: 1px 3px;
  font-size: 11px;
  height: 18px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: $default-school-subject-color;

  &.canceled {
    text-decoration: line-through;
    color: #666;
  }
}
</style>
