<template>
  <v-dialog
    persistent
    scrollable
    max-width="580"
    :value="!!actualLesson"
    :fullscreen="mini"
  >
    <v-card v-if="actualLesson">
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-file-replace-outline</v-icon>
        <div class="text-truncate">
          <span>Änderung eintragen</span>
        </div>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <v-row dense>
          <v-col class="text-center pt-3">
            <strong class=" text-uppercase">
              {{ actualLesson.date | dateForHumans }}
            </strong>

            <DayPlanSlotLesson
              :day-plan-slot="dayPlanSlot"
              :actual-lessons="[initialLesson]"
              disabled
            />
          </v-col>
        </v-row>
        <v-row
          class="mt-6"
          dense
        >
          <v-col>
            <v-autocomplete
              v-model="schoolSubject"
              :items="$store.state.common.schoolSubjects"
              :readonly="loading"
              item-text="name"
              item-value="@id"
              label="Fach"
              prepend-icon="mdi-school-outline"
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-autocomplete
              v-model="teachers"
              :items="$store.state.common.teachers"
              :readonly="loading"
              :item-text="(item) => `${item.displayName} (${item.code})`"
              item-value="@id"
              label="Lehrkraft"
              prepend-icon="mdi-account-outline"
              dense
              outlined
              multiple
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-autocomplete
              v-model="classrooms"
              :items="$store.state.common.classrooms"
              :readonly="loading"
              item-text="code"
              item-value="@id"
              label="Raum"
              prepend-icon="mdi-map-marker-outline"
              dense
              outlined
              multiple
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              v-model.trim="comment"
              :counter="1000"
              :readonly="loading"
              label="Vertretungshinweise"
              prepend-icon="mdi-comment-text-outline"
              rows="3"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          :disabled="loading"
          text
          @click="$emit('close')"
        >
          Abbrechen
        </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          @click="saveAndClose()"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { dateForHumans } from '@/helpers/datetime'
import DayPlanSlotLesson from '@/components/schedule-day/day-plan-slot-lesson'
import ActualLesson from '@/entities/actual-lesson'

export default {
  name: 'EditActualLessonReplacementDialog',
  components: {
    DayPlanSlotLesson
  },
  filters: {
    dateForHumans
  },
  props: {
    dayPlanSlot: Object,
    actualLesson: Object,
    mini: Boolean
  },
  data: () => ({
    loading: false,
    isCanceled: null,
    schoolSubject: null,
    teachers: [],
    classrooms: [],
    comment: '',
    error: null
  }),
  computed: {
    initialLesson() {
      return new ActualLesson({
        ...this.actualLesson,
        replacement: null
      })
    }
  },
  created() {
    this.resetForm()
  },
  methods: {
    resetForm() {
      this.error = null
      const { replacement, scheduledLesson } = this.actualLesson || {}
      const {
        schoolSubject = null,
        teachers = [],
        classrooms = []
      } = replacement || scheduledLesson || {}
      this.schoolSubject = schoolSubject
      this.teachers = [...teachers]
      this.classrooms = [...classrooms]
      this.comment = replacement?.comment || ''
    },
    async saveAndClose() {
      this.error = null
      this.loading = true

      const patch = {
        teachers: this.teachers,
        schoolSubject: this.schoolSubject,
        classrooms: this.classrooms,
        comment: this.comment.slice(0, 1000).trim()
      }

      const done = () => {
        this.loading = false
        this.$emit('close')
      }

      const error = msg => {
        this.error = msg || 'Fehler!'
        this.loading = false
      }

      this.$emit('save', {patch, done, error})
    }
  }
}
</script>


<style lang="scss" scoped>
.day-plan-slot-lesson {
  max-width: 380px;
  margin: 0 auto;
}
</style>
